import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors } from 'herald-fe-shared';

import { Container } from '~/layout/Layout';

import Section from '~/components/Section';

import UseCaseConsolidate from '~/illustrations/UseCaseConsolidate';
import UseCaseAlign from '~/illustrations/UseCaseAlign';
import UseCaseInvolve from '~/illustrations/UseCaseInvolve';
import UseCasePrioritize from '~/illustrations/Flag';

export const USE_CASES = [
  {
    slug: 'consolidate-feedback',
    title: 'Consolidate Customer Feedback',
    short: 'Consolidate',
    illustration: <UseCaseConsolidate />,
    description: (
      <>
        Large volumes of customer feedback lead to a host of problems:
        fragmentation, poor recollection, inaccurate translation, and more.
        Herald consolidates feedback in one place, giving you the time to focus
        on serving customer needs.
      </>
    ),
  },
  {
    slug: 'qualify-feedback',
    title: 'Qualify Customers and Quantify Needs',
    short: 'Qualify',
    illustration: <UseCasePrioritize />,
    description: (
      <>
        Don't let your product roadmap be biased by the loudest opinions, recent
        remarks, or gut feelings. Herald provides all the tools your team needs
        to take a data-driven approach to quantifying user feedback to make the
        right call on prioritization.
      </>
    ),
  },
  {
    slug: 'align-team',
    title: 'Align Your Team',
    short: 'Align',
    illustration: <UseCaseAlign />,
    description: (
      <>
        Keeping all team members on the same page is critical for any successful
        startup. Herald provides the tools you need to align their team around
        customer needs.
      </>
    ),
  },
  {
    slug: 'engage-customers',
    title: 'Continuously Engage With Customers',
    short: 'Engage',
    illustration: <UseCaseInvolve />,
    description: (
      <>
        Herald helps organizations keep customers in the loop throughout their
        development process. By bringing customers front-and-center, Herald
        kickstarts a virtuous cycle of continuous customer feedback.
      </>
    ),
  },
];

const StyledOpener = styled.div`
  text-align: center;
  margin: auto;
  margin-bottom: 4rem;
  .container {
    flex-flow: column;
  }
  .container__text {
    margin: auto;
    max-width: 50rem;
  }
  .container__text h1 {
    margin-top: 1rem;
  }
  .container__text label {
    color: ${colors.PURPLE_LIGHT()};
  }
  .section-links {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
  }
  .opener {
    padding: 3rem 0;
  }
  .section-links {
    margin-bottom: 2rem;
  }
  .section-links__link {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    padding-right: 10px;
    border: 1px solid ${colors.PURPLE_LIGHT(0.6)};
    border-right: none;
    font-size: 0.8rem;
  }
  .section-links__link:first-child {
    border-radius: 5px 0 0 5px;
  }
  .section-links__link:last-child {
    border-radius: 0 5px 5px 0;
    border-right: 1px solid ${colors.PURPLE_LIGHT(0.6)};
  }
  .section-links__link.active {
    background: ${colors.PURPLE_LIGHT(0.8)};
    color: ${colors.WHITE()};
  }
  .section-links__link span {
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
    background: ${colors.PURPLE_LIGHT(0.15)};
    color: ${colors.PURPLE_LIGHT()};
    font-weight: bold;
    border-radius: 50%;
    font-size: 0.7rem;
    line-height: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -1px;
  }
  .section-links__link.active span {
    background: ${colors.WHITE(0.2)};
    color: ${colors.WHITE()};
  }
  .section-links__spacer {
    flex: 1 1 auto;
  }
  .opener__text {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 60rem;
    margin: auto;
  }
  .opener__text__illustration {
    flex: 0 0 25rem;
    margin-right: 4rem;
    height: 20rem;
  }
  .opener__text__illustration svg {
    width: 100%;
    height: 100%;
  }
  .opener__text__p p {
    text-align: left;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 2rem;
    h1 {
      font-size: 3.5rem;
      line-height: 4rem;
    }
    .opener {
      padding-bottom: 1rem;
    }
    .opener__text {
      flex-flow: column;
    }
    .opener__text__illustration {
      margin: 0;
      width: 70%;
      flex: 0 0 20rem;
      margin-bottom: 2rem;
    }
  }
`;

interface IOpenerProps {
  sectionIndex: number;
}

const Opener: React.FC<IOpenerProps> = (props) => {
  const { sectionIndex } = props;
  const { title, illustration, description } = USE_CASES[sectionIndex];

  return (
    <StyledOpener>
      <Section className="opener">
        <Container className="container">
          <div className="container__text">
            <label>Herald Use Cases</label>
            <div className="section-links">
              {USE_CASES.map((s, i) => (
                <Link
                  key={s.slug}
                  to={`/use-cases/${s.slug}/`}
                  getProps={(p) => {
                    const a = p.href;
                    const b = p.location.pathname;
                    return {
                      className: `section-links__link ${
                        a.startsWith(b) || b.startsWith(a) ? 'active' : ''
                      }`,
                    };
                  }}
                >
                  <span>{i + 1}</span>
                  {s.short}
                </Link>
              ))}
            </div>
            <h1>{title}</h1>
          </div>
        </Container>
      </Section>
      <Container className="opener__text">
        <div className="opener__text__illustration">{illustration}</div>
        <div className="opener__text__p">
          <p>{description}</p>
        </div>
      </Container>
    </StyledOpener>
  );
};

export default Opener;
