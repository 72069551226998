import * as React from "react";

const SvgUseCaseAlign = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 346 260" fill="none" {...props}>
    <path
      d="M52.143 17.524C56.943-3.176 86.4 1.34 101.888 1.34c12.421 0 92.875-5.364 101.344 5.364 12.736 16.132-34.638 9.137-40.65 19.62-19.033 33.183 117.985-1.568 94.286 28.462-11.798 14.95-48.272-.247-48.272 19.386 0 19.712 23.192 7.848 26.192 19.712 6.268 24.788 52.905 1.473 48.898 26.726-3.105 19.57-51.155 3.778-61.258 18.772-6.807 10.103-12.619 14.067-8.045 25.358 8.625 21.292 62.151 10.195 74.605 29.499 11.984 18.574 22.104 56.6 0 56.6H18.408c-3.527 0 0-9.033 0-9.033 2.822-26.071 57.73-8.075 57.73-34.299 0-16.747-30.568-11.384-55.55-11.384-16.702 0-8.854-16.073-2.18-31.383 13.687-31.394 101.804 13 85.96-17.362-9.603-18.401-49.59-3.351-47.426-23.995 2.066-19.711 40.628.551 47.426-18.066 8.929-24.454-71.343 1.814-65.996-23.664 7.199-34.299 67.519 1.978 65.996-26.867-1.322-25.02-57.883-12.856-52.225-37.263z"
      fill="#EEE6FA"
      fillOpacity={0.4}
    />
    <path
      d="M99.826 161.199V124.08l28.282-42.17 196.707-19.696v188.374H99.826v-89.389z"
      fill="#ABC0F6"
    />
    <path
      d="M104.624 242.761V124.585l26.514-36.362 186.859-19.19V242.76H104.624z"
      fill="#FBFBFB"
    />
    <path
      d="M140.228 215.994h-16.666v18.181m16.666-18.181v-12.625h8.586v-16.414m-8.586 29.039h41.917m-33.331-29.039h-13.131m13.131 0h10.1m-23.231 0l-2.02-17.676m2.02 17.676h-21.869m19.849-17.676l12.626-2.02v-11.868m-12.626 13.888l-10.101-13.888m22.727 0h9.848m-9.848 0h-22.727m32.575 0v-20.706c0-2.272 5.302-20.958 29.796-21.211m-29.796 41.917l2.777 31.564m-2.777-31.564l26.008 13.888m3.788-55.805c19.595-.202 20.453-10.353 18.433-15.403h21.969m-40.402 15.403v26.767l7.323 5.302m-7.323-32.069V92.011m40.402 6.06l1.768 47.472m-1.768-47.472l24.494 47.472m-22.726 0h-34.847m34.847 0h22.726m-57.573 0v23.736m0 0h9.722m-9.722 0h-11.111m48.23 8.838v-8.838h-17.676m17.676 8.838h-17.676v-8.838m17.676 8.838v17.929m0-17.929h20.454m-38.13-8.838h-9.721m0 0v26.767m0 0h13.698m-13.698 0v30.806m0-30.806l-20.833 30.806m48.23-30.806v15.151h20.454v-33.08m-20.454 17.929h-13.699m34.153-17.929v-32.574m-34.153 50.503v30.806h-13.698m0 0h-20.833m0 0v-10.858m0-46.715v34.09m-23.231-16.414l11.363 16.414h11.868m0 12.625v-12.625m3.788-111.358h49.745V81.153h25.504M185.933 92.01l.252-9.09m74.997-1.768v19.696m0-19.696h23.483v33.963m-23.483 119.059h-137.62m137.62 0h49.492v-22.978m-49.492 22.978V129.383m-137.62 104.792v8.333m-9.748-55.553l.91 47.22v8.333m-.91-55.553l.91-31.564h8.838m187.112 55.806h-40.149V173.32m40.149 37.877l-40.149-37.877m40.149 37.877V173.32m-40.149 0h40.149m-40.149 0v-23.989m40.149 23.989l-2.525-43.937m-37.624 19.948l37.624-19.948m-37.624 19.948v-14.646l-9.343-5.302m46.967 0l-23.484-14.267m-23.483-14.267v28.534m0-28.534l23.483 14.267"
      stroke="#686EFF"
      strokeOpacity={0.12}
      strokeWidth={2.008}
      strokeLinecap="round"
    />
    <path
      d="M128.36 81.153l-28.534 42.169 21.969 2.273 6.565-44.442z"
      fill="#94B0F7"
    />
    <path
      d="M205.859 170.376c-6.153-6.127-9.75-18.03 0-18.338 8.875-.281 7.307 10.895 0 18.338z"
      fill="#40E2F8"
    />
    <path
      d="M205.872 165.538c-3.676-3.661-5.825-10.773 0-10.957 5.303-.168 4.366 6.509 0 10.957z"
      fill="#fff"
      fillOpacity={0.19}
    />
    <path
      d="M102.143 224.323c8.121 4.493-3.384 19.583-10.152 26.567H79.68c-8.748-3.996-24.882-12.657-19.44-15.336 6.804-3.347-3.78-7.127-6.803-15.659-3.024-8.531 8.964-8.315 17.603-8.315 8.64 0 1.836-11.232 10.908-13.824 9.071-2.592 5.94 7.452 5.94 13.824 0 6.371 4.103 7.127 14.255 12.743z"
      fill="#8280FF"
    />
    <path
      d="M79.108 212.752c2.291 3.818 3.868 25.047 7.036 37.719H82.58c.54-1.728-10.475-7.344-14.04-9.612-3.563-2.268.433-6.156 1.837-6.804 1.404-.648-11.772-6.696-9.72-14.471 1.523-5.768 9.489 6.806 11.664 1.08 1.38-3.634 5.167-10.612 6.787-7.912z"
      fill="#fff"
      fillOpacity={0.13}
    />
    <path
      d="M87.008 250.687c-.664-1.835-1.483-4.334-2.321-7.236m-1.135-38.769c-2.484 3.564-.215 5.385-1.619 11.864-1.578 7.285-.405 14.872 1.62 22.748m0 0c-4.608-6.21-10.476-11.934-19.98-17.766m19.98 17.766c.373 1.452.755 2.846 1.134 4.157m0 0c2.61-3.707 8.887-11.577 13.12-13.391"
      stroke="#FBFBFB"
      strokeWidth={0.966}
      strokeLinecap="round"
    />
    <path
      d="M73.693 219.205c-9.722 5.378 4.05 23.442 12.151 31.801h14.737c10.472-4.783 29.785-15.15 23.27-18.356-8.145-4.008 4.524-8.532 8.144-18.745 3.619-10.212-10.73-9.954-21.072-9.954-10.342 0-2.197-13.444-13.056-16.547-10.86-3.102-7.11 8.92-7.11 16.547 0 7.627-4.913 8.532-17.064 15.254z"
      fill="#DEC8FA"
    />
    <path
      d="M101.265 205.356c-2.743 4.571-4.63 29.982-8.422 45.15h4.266c-.647-2.068 12.539-8.791 16.805-11.506 4.266-2.714-6.756-3.12-6.756-6.593 0-5.41 18.649-9.566 16.193-18.873-1.822-6.905-11.358 8.147-13.961 1.292-1.652-4.35-6.186-12.702-8.125-9.47z"
      fill="#fff"
      fillOpacity={0.13}
    />
    <path
      d="M91.809 250.765c.794-2.197 1.776-5.188 2.778-8.661m1.358-46.408c2.974 4.266.258 6.445 1.939 14.201 1.889 8.72.485 17.802-1.939 27.23m0 0c5.516-7.434 12.54-14.285 23.916-21.266m-23.916 21.266a176.54 176.54 0 01-1.358 4.977m0 0c-3.124-4.439-10.638-13.858-15.706-16.03"
      stroke="#FBFBFB"
      strokeWidth={1.157}
      strokeLinecap="round"
    />
    <path
      d="M278.895 237.438c-57.409-59.264-62.844-159.347 0-162.07 57.201-2.48 65.32 91.712 0 162.07z"
      fill="#8AA3FB"
    />
    <path
      d="M278.894 237.439c-57.409-59.264-62.844-159.348 0-162.071v162.071z"
      fill="#000"
      fillOpacity={0.02}
    />
    <path
      d="M279.131 190.784c-30.411-31.393-33.29-84.41 0-85.852 30.301-1.313 34.601 48.582 0 85.852z"
      fill="#fff"
      fillOpacity={0.21}
    />
    <path
      d="M279.336 164.178c-15.857-16.369-17.358-44.014 0-44.766 15.8-.685 18.043 25.332 0 44.766z"
      fill="#fff"
      fillOpacity={0.21}
    />
    <path
      d="M343.459 251.634H3"
      stroke="#000"
      strokeWidth={4.034}
      strokeLinecap="round"
    />
    <path
      d="M182.452 110.874l-15.006 29.777c7.878 0 18.133 3.752 22.275 5.628 1.501-3.939 13.756-21.65 19.695-30.012l-26.964-5.393z"
      fill="#DAE9FF"
    />
    <path
      d="M172.604 137.072l6.378-12.754m8.16.561l8.206-1.641 3.618 1.641m-11.824 0l-1.173 1.599m1.173-1.599l.82-3.477m11.004 3.477c1.388-2.192 2.355-3.739 2.478-3.986.276-.55-6.501-1.892-12.661-2.968m10.183 6.954a3538.195 3538.195 0 01-10.183 15.944l-2.814-.738m10.083-12.157l-10.083-1.45m0 0l-1.063 1.45m1.063-1.45l-6.987-2.16m5.924 3.61l-5.502 7.503 5.862 1.641 3.517-6.331-7.738-2.813-1.641 2.813 5.862 1.173-1.407 2.344-6.8-2.344-2.579 5.158 11.489 3.013m-1.063-12.157l7.628 1.641-6.565 10.516m2.814-22.16c-2.236-.391-4.39-.747-6.096-1.018l-1.642 3.283m7.738-2.265l-.821 3.477m0 0l-6.917-1.212m0 0l-2.063 4.128"
      stroke="#359EFF"
      strokeOpacity={0.19}
      strokeWidth={0.932}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M139.775 246.006c-.014 1.266-.065 5.977.003 7.894h4.69l.938-10.785-5.628.234v2.345l-.003.312zm10.789-1.719v9.613l4.455-.469.704-9.847-5.159.703z"
      fill="#B44343"
    />
    <path
      d="M141.257 247.651l-1.627-.217v5.207l1.41.217.217-5.207zM152.104 248.194l-1.627-.217v5.206l1.41.217.217-5.206z"
      fill="#000"
      fillOpacity={0.13}
    />
    <path
      d="M168.853 130.335l-13.834 5.861-4.924 1.876 2.814 7.738 4.22-2.814 14.537-10.317s5.696-1.875 6.097-2.344c1.058-1.241-2.881.283-3.432-1.252-.776-2.159 3.17-5.706 2.536-6.72-.352-.562-8.014 7.972-8.014 7.972z"
      fill="#C54040"
    />
    <path
      d="M167.211 134.79c-5.627 4.22-11.723 6.799-11.723 6.799l-1.641.704v3.048l2.579-1.876 15.006-10.551s-3.172 1.089-4.221 1.876z"
      fill="#000"
      fillOpacity={0.12}
    />
    <path
      d="M141.997 114.248c0 .75-1.522 3.601-1.522 5.453 4.221.937 4.24 1.767 7.053 2.683l1.95-6.107s1.014.38 3.17.38c2.156 0 6.612-13.946 3.778-17.016-2.834-3.07-6.353-2.99-11.02 0-4.668 2.989-3.409 13.857-3.409 14.607z"
      fill="#C44949"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M157.364 166.912c-3.377 2.814-16.257 1.172-22.275 0 2.11-6.565 2.366-10.921 0-19.596-2.366-8.676.387-28.968 8.53-29.459 3.453-.208 6.478 5.139 8.804 12.292 1.616 3.489 2.835 7.379 3.534 10.267l-.792.388c1.46 7.199 2.258 14.462 2.199 19.074v7.034zm-2.095 93.052c7.01.049 15.407.107 15.927-.264 1.642-1.173.617-3.362-.761-3.805-1.274-.409-2.689-.776-4.014-1.12-1.836-.477-3.498-.908-4.369-1.344a39.399 39.399 0 00-1.833-.84c-1.222-.536-2.268-.994-2.584-1.468-.159-.24-.84.104-1.778.579-1.82.92-4.61 2.331-6.466.939 0 0-.132.387-.277.953-1.815-.758-3.005-1.335-3.005-1.335-2.358.708-4.202 1.033-7.972 0-.706 3.704-.418 4.951-.186 5.958.098.424.186.806.186 1.311h11.136c.07.255.118.406.118.406 1.636 0 3.689.015 5.878.03z"
      fill="#88A1FB"
    />
    <path
      d="M171.197 259.7c-.682.487-14.927.234-21.805.234 0 0-.717-2.263-.65-4.039.056-1.509.65-3.254.65-3.254 2.813 2.11 7.775-2.222 8.244-1.519.469.704 2.541 1.371 4.417 2.309 1.5.75 5.346 1.488 8.383 2.464 1.378.443 2.403 2.632.761 3.805z"
      fill="#fff"
      fillOpacity={0.09}
    />
    <path
      d="M160.763 253.094c-.93-.718-2.511-2.104-1.395-1.902 1.116.203 1.395 1.353 1.395 1.902zm0 0c.338-1.056 1.065-2.916 1.268-1.902-.211.55-.761 1.7-1.268 1.902zm0 0c.549.339 2.409.634 3.424 1.649m-3.551-1.649c-.169.465-.507 1.015-1.648 2.917"
      stroke="#000"
      strokeWidth={0.504}
      strokeLinecap="round"
    />
    <path
      d="M151.033 109.232c.704-.703 2.58-7.737 2.58-7.737 0-1.485-3.095-2.064-5.159-2.814-2.063-.75-4.34 1.407-4.809 2.814-2.263 3.086-2.084 9.785-1.681 12.674 0 0 2.566-.997 3.957-1.565 2.479-1.012 1.196-3.372 2.842-4.624 1.646-1.253 1.299 2.223 2.27 1.252z"
      fill="#070707"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M157.364 166.912l4.22 41.736-4.22 40.329-9.141-.994-.004.056-10.082-.703 3.283-34.468-3.011-22.365c-3.139-6.132-5.231-12.86-3.32-23.591 5.627 1.173 17.351 2.814 22.275 0z"
      fill="#0F3188"
    />
    <path
      d="M161.584 208.648l-4.22-41.736c-4.924 2.814-16.648 1.173-22.275 0-2.438 13.693 1.642 20.868 6.096 28.606 4.455 7.737 7.504 16.178 7.504 16.178l-2.111 36.108 10.786 1.173 4.22-40.329z"
      fill="#fff"
      fillOpacity={0.04}
    />
    <path
      d="M155.033 248.628h2.061l4.447-39.701-.868-9.545s-.203 5.821-.434 9.545c-.965 15.607-5.206 39.701-5.206 39.701z"
      fill="#000"
      fillOpacity={0.1}
    />
    <path
      d="M188.533 141.129c-3.518 2.58-21.946 14.059-32.575 18.045-5.758-4.277-8.189-7.892-10.552-13.599l9.614-4.924 3.751 7.972 30.012-12.058 2.286-3.297s.747-.901 1.014-.634c.127.127-.524 1.444-.761 2.155-.127.381 3.297-3.909 3.297-3.909l.507-.275c.75 0-.945 2.065-1.648 3.55l3.075-2.277a.561.561 0 01.687.013c.158.125.171.36.028.503l-3.41 3.41 3.788-2.066a.436.436 0 01.438.754l-3.972 2.453 2.885-.888a.318.318 0 01.227.593l-6.155 2.831-2.536 1.648z"
      fill="#C44949"
    />
    <path
      d="M158.771 151.375c-2.579-.469-6.565-9.317-6.565-9.317l2.813-1.407 3.752 7.972 19.93-7.503c-.938 1.313-17.351 10.724-19.93 10.255z"
      fill="#000"
      fillOpacity={0.08}
    />
    <path
      d="M155.739 140.034l-10.793 5.654c-6.941-16.882-4.961-25.933-2.929-26.793 6.565-3.188 11.925 13.714 13.722 21.139z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      d="M136.73 131.094l1.172 11.724v1.608l5.363-3.17.634 1.902-5.997 3.424v17.807s.165 6.905-3.282 10.082c-3.843 3.543-7.712 4.024-12.662 2.345-2.602-.883-4.192-1.534-6.096-3.517l-6.096-3.517v-8.441l3.986-2.579c.212-6.393 2.473-9.677 3.752-15.944.484-2.376.313-3.829 1.172-6.096.624-1.647 2.11-3.986 2.11-3.986s-4.599-6.851-3.282-10.786c.903-2.702 4.454-4.22 4.454-4.22l1.173-.245s-2.499-6.372 0-7.962c2.579-1.641 8.599.899 12.896 3.048 3.282 1.642 1.875 4.914 1.875 4.914l-.234 1.886 1.217 1.217s1.112-1.443 2.097-2.029c.873-.519 1.267-.808 2.283-.829.851-.018 1.368-.002 2.155.322.944.389 1.308.926 2.029 1.648.924.926 2.283 3.551 2.283 3.551l-.254.253s-.689-1.21-1.268-2.028c-.563-.796-.881-1.504-1.648-2.105-1.066-.834-1.947-1.153-3.297-1.065-1.868.121-3.956 2.706-3.956 2.706l-1.407 10.082h-1.172z"
      fill="#FA83AD"
    />
    <path
      d="M157.814 106.622c-.654 0-10.499-2.864-14.93-4.091 4.202-11.57 17.153-6.395 15.09 2.714 0 0 3.043 1.377 3.93 1.902.167.099 0 .497 0 .497l-4.09-1.022z"
      fill="#577BF9"
    />
    <path
      d="M116.097 156.596c0-.869-2.11.703-2.11.703-2.814 11.02 1.016 15.944 3.048 16.882l-.234-3.752s-1.605-3.473-1.876-5.861c-.355-3.127 1.172-5.862 1.172-7.972z"
      fill="#000"
      fillOpacity={0.08}
    />
    <path
      d="M130.635 133.383l5.861-2.345 1.173 11.489v24.619c0 3.189-2.189 6.331-3.283 7.503l-3.751.704-3.049-5.628 4.221-16.647-1.172-19.695z"
      fill="#644DF1"
      fillOpacity={0.16}
    />
    <path
      d="M131.337 124.942l-.703 8.441 6.096-2.345h1.172l1.407-10.082-3.282-3.283-4.221-.469-.469 7.738z"
      fill="#000"
      fillOpacity={0.1}
    />
    <path
      d="M133.916 114.625l-1.172 2.579 3.283.469 1.641 1.407.469-3.752-.938-2.11-3.283-1.641v3.048z"
      fill="#000"
      fillOpacity={0.13}
    />
    <path
      d="M149.158 258.997h22.978l-.938.938h-22.04v-.938zM138.137 258.763h10.786l.234.703h-11.02v-.703z"
      fill="#3C48B4"
    />
  </svg>
);

export default SvgUseCaseAlign;
