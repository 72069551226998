import styled from 'styled-components';
import { colors } from 'herald-fe-shared';

const Section = styled.article`
  width: 100vw;
  padding: 6rem 0;
  overflow: hidden;
  &.section--gray {
    background: #f3f3f3;
  }
  @media screen and (max-width: 800px) {
    padding: 4rem 0;
  }
  .section__container {
    margin: 0 -2rem;
    width: calc(100% + 4rem);
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(8, 12.5%);
  }
  .section__container:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    border-left: 1px solid ${colors.PURPLE_LIGHT()};
    height: 32px;
  }
  .section__container--none:after {
    display: none;
  }
  .section__container--1:after {
    left: 25%;
  }
  .section__container--2:after {
    left: 50%;
  }
  .section__container--3:after {
    left: 75%;
  }
  .section__container__col {
    padding: 0 2rem;
  }
  .section__container__col--2 {
    grid-column: span 2;
  }
  .section__container__col--3 {
    grid-column: span 3;
  }
  .section__container__col--4 {
    grid-column: span 4;
  }
  .section__container__col--5 {
    grid-column: span 5;
  }
  .section__container__col--6 {
    grid-column: span 6;
  }
  .section__container__col--7 {
    grid-column: span 7;
  }
  .section__container__col--8 {
    grid-column: span 8;
  }
  .section__container__col--no-padding {
    padding: 0;
  }
  .section__title {
    margin-bottom: 0.5rem;
  }
  .section__subtitle {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 600px) {
    .section__container {
      grid-template-columns: repeat(4, 25%);
    }
    .section__container__col--5 {
      grid-column: span 4;
    }
    .section__container__col--6 {
      grid-column: span 4;
    }
    .section__container__col--7 {
      grid-column: span 4;
    }
    .section__container__col--8 {
      grid-column: span 4;
    }
    .section__container__col--mobile-center {
      max-width: 40rem;
      margin: auto;
      display: flex;
      flex-flow: column;
      align-items: center;
      text-align: center;
    }
  }
`;

export default Section;
