import * as React from "react";

const SvgUseCaseInvolve = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 298 251" fill="none" {...props}>
    <path
      d="M41.446 249.796h242.979s35.264-116.189-8.358-204.248c-6.979-14.088-16.293-24.293-33.022-32.615-25.867-12.868-48.199-18.709-73.79-5.3-19.042 9.977-18.275 28.923-34.857 42.603-26.638 21.976-78.82-2.144-82.352 32.207-.988 9.609 5.014 14.896 3.669 24.461-3.546 25.209-45.324 12.102-54.426 35.876-7.559 19.745 21.73 31.758 7.543 53.61-14.188 21.852 15.831 44.709 32.614 53.406z"
      fill="#FFEFED"
    />
    <path
      d="M78.704 249.53h205.924s20.181-156.488-5.707-177.483c-10.682-8.663-25.684-3.466-34.77-16.905-13.279-19.643-39.556-15.354-60.558-4.35-15.626 8.188-14.997 23.737-28.606 34.963-21.861 18.035-64.685-1.76-67.584 26.432-.81 7.886 4.115 12.224 3.011 20.074-2.91 20.688-37.196 9.932-44.665 29.443-6.204 16.204 17.832 26.063 6.19 43.996-11.644 17.934 12.992 36.692 26.765 43.83z"
      stroke="#fff"
      strokeWidth={2.677}
      strokeDasharray="12.49 12.49"
    />
    <path
      d="M115.582 249.257h147.643s39.546-115.242 7.542-146.022c-7.789-7.492-3.585-12.829-15.084-18.55-17.78-8.845-37.446-17.37-55.037-8.154-13.088 6.858-13.878 26.269-25.276 35.673-18.31 15.105-50.074-1.596-52.502 22.017-.679 6.605 3.447 10.239 2.522 16.814-2.437 17.328-31.155 8.318-37.411 24.66-5.196 13.572 14.936 21.831 5.184 36.851-9.752 15.021 10.883 30.732 22.419 36.711z"
      stroke="#fff"
      strokeWidth={2.677}
      strokeDasharray="12.49 12.49"
    />
    <path
      d="M262.41 234.304s1.019-37.914 0-58.502c-.252-5.091-1.631-14.269-1.631-14.269l-58.094 1.834-1.835 69.714 30.78.612 30.78.611z"
      fill="#2A69C8"
    />
    <path
      d="M260.168 167.445v62.171"
      stroke="#000"
      strokeOpacity={0.1}
      strokeWidth={0.892}
      strokeDasharray="8.92 8.92"
    />
    <path
      d="M232.242 229.82l-1.02-30.984-3.873 30.984h4.893z"
      fill="#000"
      fillOpacity={0.03}
    />
    <path
      d="M251.81 237.362l-1.834-58.095s-.31-10.872 0-12.434c.931-4.696 11.415-2.038 11.415-2.038l1.019 9.784V234.712l-10.6 2.65z"
      fill="#000"
      fillOpacity={0.04}
    />
    <path
      d="M232.038 228.598l-.816-27.723v-17.938"
      stroke="#000"
      strokeOpacity={0.13}
      strokeWidth={0.892}
    />
    <path
      d="M232.446 76.532c-2.474-1.342-1.631-7.746-1.631-7.746 1.631 4.28 2.446 5.911 5.504 7.134 0 0-2.404 1.409-3.873.612z"
      fill="#FF3636"
      fillOpacity={0.04}
    />
    <path
      d="M215.119 77.347l-5.096 1.63-6.116 2.447 23.85 15.695 19.161-17.326-4.892-1.835s-7.135-1.63-8.969-4.484c-2.029-3.156-3.262-8.358-3.262-8.358-5.544 5.382-12.91 4.01-15.899 2.65 0 0 1.439 3.558 1.834 5.708.32 1.738-.611 3.873-.611 3.873z"
      fill="#FFBCBC"
    />
    <path
      d="M227.145 69.193c-.326 4.077-8.153 7.135-12.026 8.154.815-2.039 1.019-3.67 0-6.115.978.326 8.425-1.156 12.026-2.039z"
      fill="#FF2929"
      fillOpacity={0.04}
    />
    <path
      d="M188.212 135.238l2.242-20.181 11.619-1.63v27.11L175.37 175.19h-12.842l25.684-39.952z"
      fill="#FFBCBC"
    />
    <path
      d="M189.842 135.849l2.039-18.55h-1.427l-2.039 17.939-16.511 25.276 1.223 1.223 16.715-25.888z"
      fill="#fff"
      fillOpacity={0.1}
    />
    <path
      d="M197.996 137.888l-26.907 37.099h4.485l26.092-34.042-.408-8.765-3.262 5.708z"
      fill="#FF2D2D"
      fillOpacity={0.05}
    />
    <path
      d="M197.996 163.163v-12.434s-.137 1.532 0-6.523c.149-8.757 0-26.907 0-26.907h-11.823c5.3-37.507 18.754-38.05 28.13-39.749 4.485 9.377 16.715 3.737 21.404-1.834 30.984 5.3 34.721 31.663 33.633 42.806l-13.249 2.446 1.631 23.238 2.038 6.523c0 3.669 6.115 14.677 0 16.715-7.95 2.65-61.764 1.631-61.764-4.281z"
      fill="#FF99BE"
    />
    <path
      d="M191.473 96.1c4.485-15.084 16.511-18.142 22.83-18.55l2.446 3.466-3.669-.612c-9.58 1.02-18.957 10.872-21.607 15.696z"
      fill="#000"
      fillOpacity={0.04}
    />
    <path
      d="M254.664 168.26c-22.219-6.727-50.145-2.677-56.26-61.764v57.076c10.763 5.055 41.923 6.047 56.26 4.688z"
      fill="#000"
      fillOpacity={0.03}
    />
    <path
      d="M265.06 119.134c.326-18.753-13.725-34.857-20.792-40.564 22.504 7.012 26.228 29.556 25.276 39.952l-4.484.612z"
      fill="#000"
      fillOpacity={0.01}
    />
    <path
      d="M251.198 137.887V121.58l-5.3 19.365 5.3-3.058z"
      fill="#000"
      fillOpacity={0.02}
    />
    <path
      d="M198.2 117.3h-11.823s6.574-2.534 9.173-5.912c1.758-2.285 2.424-5.904 2.65-8.976v-5.7s.23 2.565 0 5.7V117.3z"
      fill="#000"
      fillOpacity={0.04}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M174.147 213.921l30.576-17.531-5.912-10.302 12.435-12.732-11.415-19.161-14.4 7.338-.888-1.63-9.173 5.122-7.339-10.83c-11.089 4.892-14.54 17.531-14.88 23.238l4.337 6.319-38.99 4.485s-2.65-4.485-5.504-2.149a1351.87 1351.87 0 01-6.93 5.614l12.434 7.134v-6.115l35.468-3.465 8.766 3.465 11.415 21.2zm33.633-40.565l-10.242 10.396-10.774-19.772 12.047-6.727 8.969 16.103z"
      fill="#7A87FF"
    />
    <path
      d="M192.288 203.321l-12.842-40.157-26.091 14.269 10.599 17.123 10.193 19.365 18.141-10.6z"
      fill="#000"
      fillOpacity={0.07}
    />
    <path
      d="M187.192 167.241l.408-1.427 9.784 18.142h-1.223l-8.969-16.715z"
      fill="#000"
      fillOpacity={0.12}
    />
    <path
      d="M169.254 168.464l-15.899 8.765c.978-12.23 8.357-19.568 12.638-22.014l3.261 13.249z"
      fill="#000"
      fillOpacity={0.08}
    />
    <path
      d="M121.148 190.887l-2.446 1.835 33.226-3.262-3.669-1.223-27.111 2.65z"
      fill="#000"
      fillOpacity={0.09}
    />
    <path
      d="M166.401 186.402c-1.976-3.885-12.027 2.854-12.027 2.854l8.358 3.465 11.415 21.199 12.638-7.134s-14.269-8.358-20.384-20.384z"
      fill="#000"
      fillOpacity={0.06}
    />
    <path
      d="M204.723 196.391l-10.396 5.911s3.577-4.238 4.484-7.542c.907-3.301 0-8.765 0-8.765l5.912 10.396z"
      fill="#000"
      fillOpacity={0.13}
    />
    <path
      d="M251.402 137.683v-15.899l17.123-3.058s-1.223 18.957-3.261 24.461c-3.993 10.781-54.222 25.684-54.222 25.684l-4.689 6.319s-4.12-1.181-5.503-3.261c-1.422-2.138-.816-6.523-.816-6.523l1.427-.612 4.281-1.631 1.223-.611 44.437-24.869z"
      fill="#FFBCBC"
    />
    <path
      d="M253.441 138.703c-1.58 6.714-46.272 23.645-46.272 23.645l44.233-24.461v-16.511h2.854s0 13.861-.815 17.327z"
      fill="#FF7575"
      fillOpacity={0.09}
    />
    <path
      d="M14.743 228.801H289.52L284.628 250H41.854s-10.378-5.3-16.104-9.988c-4.747-3.887-11.007-11.211-11.007-11.211z"
      fill="#A56652"
    />
    <path
      d="M289.521 228.801H14.539l5.708 5.707H287.89l1.631-5.707z"
      fill="#000"
      fillOpacity={0.06}
    />
    <path
      d="M96.687 216.467c-8.643 1.142-11.075 7.543-11.211 10.6v1.835h55.648c.951-2.65 1.712-8.398-2.854-10.192-5.707-2.243-5.503-7.95-12.026-14.269s-6.319 12.026-11.211 12.026c-4.893 0-.612-11.415-7.543-9.376-6.93 2.038 0 7.95-10.803 9.376z"
      fill="#237873"
    />
    <path
      d="M100.356 220.851c-5.381.978-9.852 5.707-11.415 7.949h44.438c-1.088 0 .815-6.319-3.262-3.872-3.321 1.992-3.694-7.422-5.911-7.95-4.281-1.019-5.504 6.726-10.192 4.892-1.216-.476-3.651-9.895-6.727-7.542-3.465 2.65-.204 5.3-6.931 6.523z"
      fill="#fff"
      fillOpacity={0.06}
    />
    <path
      d="M61.626 216.469c-8.643 1.141-11.075 7.542-11.211 10.599v1.835h55.649c.951-2.65 1.712-8.398-2.854-10.192-5.708-2.242-5.504-7.95-12.027-14.269s-6.319 12.027-11.211 12.027-.611-11.415-7.542-9.377c-6.93 2.038 0 7.95-10.804 9.377z"
      fill="#205350"
    />
    <path
      d="M65.296 220.852c-5.382.978-9.853 5.707-11.416 7.949h44.438c-1.087 0 .815-6.319-3.262-3.873-3.32 1.993-3.694-7.421-5.911-7.949-4.28-1.02-5.504 6.726-10.192 4.892-1.216-.476-3.65-9.895-6.727-7.542-3.465 2.65-.204 5.3-6.93 6.523z"
      fill="#fff"
      fillOpacity={0.06}
    />
    <path
      d="M241.414 216.876c-8.642 1.141-11.075 7.542-11.211 10.599v1.835h55.649c.951-2.65 1.712-8.398-2.854-10.192-5.708-2.242-5.504-7.95-12.027-14.269s-6.319 12.027-11.211 12.027-.611-11.416-7.542-9.377c-6.931 2.038 0 7.95-10.804 9.377z"
      fill="#237873"
    />
    <path
      d="M245.084 221.258c-5.382.979-9.853 5.708-11.415 7.95h44.437c-1.087 0 .815-6.319-3.261-3.873-3.321 1.993-3.695-7.422-5.912-7.95-4.28-1.019-5.504 6.727-10.192 4.893-1.215-.476-3.65-9.895-6.727-7.543-3.465 2.65-.203 5.3-6.93 6.523z"
      fill="#fff"
      fillOpacity={0.06}
    />
    <path
      d="M206.353 216.877c-8.643 1.141-11.075 7.542-11.211 10.6v1.834h55.649c.951-2.65 1.712-8.398-2.854-10.192-5.708-2.242-5.504-7.95-12.027-14.269s-6.319 12.027-11.211 12.027-.611-11.415-7.542-9.377c-6.931 2.039 0 7.95-10.804 9.377z"
      fill="#205350"
    />
    <path
      d="M210.023 221.259c-5.382.979-9.853 5.708-11.415 7.95h44.437c-1.087 0 .815-6.319-3.261-3.873-3.321 1.993-3.695-7.422-5.912-7.95-4.28-1.019-5.504 6.727-10.192 4.893-1.215-.476-3.65-9.895-6.727-7.543-3.465 2.65-.203 5.3-6.93 6.523z"
      fill="#fff"
      fillOpacity={0.06}
    />
    <path
      d="M130.013 216.649c-8.643 1.142-11.075 7.543-11.211 10.6v1.835h55.648c.952-2.65 1.713-8.398-2.853-10.192-5.708-2.242-5.504-7.95-12.027-14.269s-6.319 12.026-11.211 12.026-.612-11.415-7.542-9.376c-6.931 2.038 0 7.95-10.804 9.376z"
      fill="#25615D"
    />
    <path
      d="M133.682 221.032c-5.381.979-9.852 5.708-11.415 7.95h44.437c-1.087 0 .816-6.319-3.261-3.873-3.321 1.993-3.694-7.422-5.911-7.95-4.281-1.019-5.504 6.727-10.192 4.893-1.216-.476-3.651-9.895-6.727-7.543-3.466 2.65-.204 5.3-6.931 6.523z"
      fill="#fff"
      fillOpacity={0.06}
    />
    <path
      d="M238.764 168.871l3.058-10.803 8.357-3.466 8.562-5.3 1.019 3.262 2.65 8.357v3.873s-.795 1.455-1.631 2.039c-1.013.708-3.058.815-3.058.815s-5.833.576-9.58.816c-3.658.234-9.377.407-9.377.407z"
      fill="#000"
      fillOpacity={0.01}
    />
    <path
      d="M247.529 107.107l.014.093c.145.948 1.47 8.929 5.286 11.526 1.811 1.232 4.281 2.039 4.281 2.039l-7.338 1.426-2.229-14.991-.014-.093z"
      fill="#000"
      fillOpacity={0.08}
    />
    <path
      d="M232.517 59.352c.408 8.154-8.442 12.639-15.084 12.639-6.642 0-9.173-9.742-9.173-17.735 0-7.993 4.977-14.269 11.619-14.269 6.642 0 12.638 11.372 12.638 19.365z"
      fill="#FFC1C1"
    />
    <path
      d="M227.757 54.516c-.172-3.136-1.223-7.95-1.223-7.95-3.425 1.468-9.916 3.206-13.789 3.002-3.873-.203-7.339-2.485-7.339-2.485-7.949-4.077-3.216-24.023 3.67-14.841 6.726 8.969 9.308-2.31 12.434 4.077 12.067-1.468 12.638 15.695 12.638 15.695s-.887 12.287-4.484 14.88c0 0 2.446-9.58 1.223-13.25-2.384-.651-1.572 1.852-2.519 5.153l-.611.204s.095-2.736 0-4.485z"
      fill="#231753"
    />
    <path
      d="M229.387 59.205v-4.353c1.223-2.242 2.446-1.375 2.65.256.163 1.304-.611 3.893-2.65 4.097z"
      fill="#FFAFAF"
    />
    <path
      d="M229.999 58.39c.139-.347.184-.831.18-1.325a10.014 10.014 0 00-.18-1.732c.068-.204.326-.612.815-.612m.204 1.427c-.068.408-.244 1.264-.407 1.427"
      stroke="#FF0707"
      strokeOpacity={0.12}
      strokeWidth={0.268}
      strokeLinecap="round"
    />
    <path
      d="M214.579 66.895c.951.272 3.221.408 4.688-1.223"
      stroke="#000"
      strokeWidth={0.892}
      strokeLinecap="round"
    />
    <ellipse cx={219.268} cy={57.315} rx={1.019} ry={1.427} fill="#fff" />
    <circle cx={219.063} cy={57.926} r={0.408} fill="#000" />
    <ellipse cx={213.967} cy={57.315} rx={1.019} ry={1.427} fill="#fff" />
    <circle cx={213.763} cy={57.926} r={0.408} fill="#000" />
    <path
      d="M217.637 63.634s-2.039.637-2.039 0c0-.815.395-1.86.612-3.057.173-.953.408-2.446.408-2.446"
      stroke="#FF1A1A"
      strokeOpacity={0.18}
      strokeWidth={0.357}
      strokeLinecap="round"
    />
    <path
      d="M218.452 54.665c.476-.34 1.672-.815 2.65 0M212.133 54.707c.476-.34 1.672-.815 2.65 0"
      stroke="#000"
      strokeWidth={0.892}
      strokeLinecap="round"
    />
    <ellipse
      cx={220.898}
      cy={62.819}
      rx={1.835}
      ry={0.815}
      fill="#FF6565"
      fillOpacity={0.13}
    />
    <ellipse
      cx={212.337}
      cy={62.819}
      rx={1.835}
      ry={0.815}
      fill="#FF6565"
      fillOpacity={0.13}
    />
  </svg>
);

export default SvgUseCaseInvolve;
