import * as React from "react";

const SvgUseCaseConsolidate = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 299 241" fill="none" {...props}>
    <path
      d="M66.214 40.447c18.995-4.36 2.133-24.855 23.61-33.565 15.44-6.262 27.282 8.403 41.671 0 19.485-11.378 31.013-8.764 40.028 8.71 7.76 15.041 16.564 18.937 30.152 27.023 25.643 15.26 65.556-15.662 75.379 12.515 4.455 12.78-4.267 21.136-2.56 34.561 2.675 21.048 28.797 28.569 23.325 49.068-2.261 8.467-8.154 11.193-11.378 19.342-4.491 11.353 5.675 24.9-4.978 30.863-6.64 3.717-12.001-1.373-19.485 0-19.922 3.655-20.047 31.256-40.25 32.712-15.916 1.147-21.792-14.397-37.689-15.787-15.76-1.378-24.613 14.061-39.539 8.818-8.783-3.085-9.821-12.518-18.916-14.507-9.317-2.038-14.333 7.193-23.751 5.689-11.114-1.774-12.69-13.241-23.325-16.925-16.353-5.665-29.33 14.954-43.948 5.689-9.643-6.112-7.624-16.623-15.787-24.605-5.784-5.656-12.856-4.954-16.924-11.947-7.987-13.727 12.203-21.814 16.924-36.978 3.067-9.848 6.89-6.935-10.667-31.432C-9.45 65.194 47.218 44.808 66.214 40.447z"
      fill="#FCD23D"
    />
    <path
      d="M74.037 50.972c18.995-4.361 2.275-22.011 23.751-30.72 15.441-6.263 21.025 3.743 35.414-4.66 19.485-11.378 29.307-17.474 38.321 0 7.76 15.042 16.564 18.937 30.152 27.023 25.644 15.26 65.557-15.662 75.379 12.516 4.455 12.78-4.266 21.135-2.56 34.56 2.676 21.048 28.798 28.569 23.325 49.068-2.26 8.467-8.154 11.193-11.378 19.343-4.491 11.352 5.675 24.899-4.978 30.863-6.639 3.716-12-1.374-19.484 0-19.923 3.654-20.048 31.255-40.25 32.711-15.917 1.147-21.793-14.396-37.69-15.787-15.76-1.378-24.612 14.061-39.538 8.818-8.784-3.085-9.822-12.517-18.916-14.507-9.318-2.038-14.333 7.193-23.752 5.689-11.113-1.774-12.69-13.241-23.325-16.924-16.352-5.665-28.113 16.199-42.73 6.934-9.643-6.112.198-18.614-7.964-26.596-5.785-5.657-4.972-7.941-9.04-14.934-7.987-13.728 3.92-20.054 9.04-32.143 3.846-9.08 12.436-8.038-5.12-32.535C5.136 65.195 55.04 55.333 74.036 50.971z"
      fill="#fff"
      fillOpacity={0.16}
    />
    <path
      d="M97.847 52.203c18.996-4.36 2.276-22.01 23.752-30.72 15.441-6.263 24.488 7.921 35.414-4.659 8.759-10.085 9.484 2.92 23.693 16.511 13.084 12.516 30.597 15.553 44.658 18.868 20.907 4.93 31.29-21.285 50.348 3.035 5.273 6.729-4.978 12.602-3.271 26.027 2.675 21.048.142 15.36 14.507 31.29 5.869 6.508 14.581 12.331 11.357 20.48-4.491 11.353-6.664 15.787-11.357 22.566-4.332 6.256 3.698 31.906-6.116 31.906-20.255 0-17.048-5.15-31.716 8.818-11.784 11.222-13.828 25.711-29.725 24.321-15.76-1.379-19.635-10.687-34.561-15.93-8.783-3.085-30.017 13.212-39.112 11.223-9.317-2.039-5.941-12.421-15.36-13.925-11.114-1.774-21.876-10.823-32.51-14.507-16.353-5.665-31.607 11.954-46.224 2.689-9.643-6.112 8.163-11.68 0-19.662-5.784-5.656-4.971-7.94-9.04-14.933-7.986-13.728 3.92-20.054 9.04-32.143 3.846-9.081 12.437-8.038-5.12-32.535-17.556-24.497 32.348-34.359 51.343-38.72z"
      fill="#fff"
      fillOpacity={0.16}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M176.218 48.554a8.029 8.029 0 00-8.029 8.028v52.197a8.03 8.03 0 008.029 8.029h.808c.09.315.242.624.464.911l4.737 6.138a2.676 2.676 0 004.171.083l5.137-6.138c.259-.309.433-.648.531-.994h62.533a8.029 8.029 0 008.028-8.029V56.582a8.028 8.028 0 00-8.028-8.028h-78.381z"
      fill="#fff"
    />
    <rect
      x={155.76}
      y={57.346}
      width={33.565}
      height={46.835}
      rx={8.262}
      fill="#F97D7D"
    />
    <rect
      x={155.76}
      y={106.913}
      width={17.953}
      height={5.464}
      rx={2.676}
      fill="#4051E5"
    />
    <circle cx={172.543} cy={77.251} fill="#fff" r={8.586} />
    <rect
      x={172.152}
      y={80.373}
      width={12.489}
      height={18.734}
      rx={3.672}
      fill="#EC4035"
    />
    <rect
      x={231.637}
      y={70.868}
      width={14.659}
      height={20.454}
      rx={3.608}
      fill="#5A57F7"
    />
    <rect
      x={231.637}
      y={92.515}
      width={7.841}
      height={2.386}
      rx={1.169}
      fill="#4051E5"
    />
    <circle cx={238.967} cy={79.561} fill="#fff" r={3.75} />
    <rect
      x={238.796}
      y={80.925}
      width={5.454}
      height={8.182}
      rx={1.604}
      fill="#EC4035"
    />
    <rect
      x={195.577}
      y={62.104}
      width={17.827}
      height={24.875}
      rx={4.388}
      fill="#D1CAFD"
    />
    <rect
      x={195.577}
      y={88.43}
      width={9.535}
      height={2.902}
      rx={1.421}
      fill="#4051E5"
    />
    <circle cx={204.491} cy={72.676} fill="#fff" r={4.56} />
    <rect
      x={204.283}
      y={74.334}
      width={6.633}
      height={9.95}
      rx={1.95}
      fill="#EC4035"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M178.998 185.499c-2.226-.761-6.134-2.42-6.039-4.658.061-1.466 1.706-.427 1.706-.427l5.263.427 2.898 3.727 56.553 9.215v30.437l-28.303 15.929-58.881-13.227-2.703-29.725 2.153-.853h-5.282s-1.686-.841-1.849-1.849c-.196-1.218 1.849-2.56 1.849-2.56l4.48 1.564 4.241 1.481 23.914-9.481z"
      fill="#FB4470"
    />
    <path
      d="M181.777 185.392l-29.725 11.947 22.187 4.409 8.391-4.409-.853-11.947z"
      fill="#fff"
      fillOpacity={0.06}
    />
    <path
      d="M152.479 226.922l-2.844-30.01 61.441 11.805v31.432l-58.597-13.227z"
      fill="#000"
      fillOpacity={0.1}
    />
    <path
      d="M239.379 193.641l-28.019 15.361v31.005l28.019-15.787v-30.579z"
      fill="#fff"
      fillOpacity={0.06}
    />
    <path
      d="M219.894 204.166l-36.979-6.969-8.249 4.409 36.41 6.827 8.818-4.267z"
      fill="#000"
      fillOpacity={0.04}
    />
    <path
      d="M118.3 177.412c-10.145 1.565-29.725-2.048-26.88-29.014-4.93 12.327-6.457 35.386 26.88 29.014z"
      stroke="#000"
      strokeWidth={0.669}
    />
    <path
      d="M93.98 85.962c1.436-9.814 9.055-12.137 15.644-13.227 6.6-1.138 5.689-.853 8.676 3.413 2.987 4.267 4.734 3.754 6.969 7.112 2.416 3.629 7.607 3.477 7.965 7.822.234 2.838-4.362 6.02-2.56 6.827 1.801.806 5.376 3.186 5.262 6.258-.114 3.072-5.073 5.167-7.538 5.831-2.465.664 2.276 3.271 2.276 3.271s1.007 3.95 0 6.116c-2.307 4.959-14.792 2.986-14.792 2.986-3.84 3.272-15.929-.995-18.347 1.28-2.418 2.276-18.489 13.085-20.196 13.085-1.365 0-3.413-2.465-4.266-3.698 0 0-2.655-3.829-2.418-6.542.192-2.203 2.084-2.934 2.418-5.12.498-3.266-2.808-4.826-2.418-8.107.734-6.181 14.138-1.317 14.364-7.538.165-4.526-3.456-3.414-2.418-7.822 1.06-4.5 9.942-2.134 11.379-11.947z"
      fill="#0D1453"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M105.554 106.449c-4.529-4.585-5.081-11.865-4.463-15.794 3.983-14.222 15.266-7.301 19.343-.996 3.268 11.576.278 19.109-3.353 20.727-.129 1.159-.092 2.621.508 4.021l13.227 2.844 2.56 3.84s-11.667 6.655-19.911 7.112c-9.774.541-24.036-7.112-24.036-7.112l.853-1.848s13.085-2.703 14.649-4.836c1.091-1.487.952-5.094.623-7.958z"
      fill="#FBC2C2"
    />
    <path
      d="M115.03 110.424c.568.143 1.469 0 1.991 0 0 .797.379 2.987.569 3.983l4.835.995c-1.754.427-3.808.353-4.835.427-1.992.142-3.002-5.515-2.56-5.405z"
      fill="#FC3636"
      fillOpacity={0.05}
    />
    <path
      d="M116.593 110.567c-14.649 0-16.45-13.891-15.502-19.912 3.982-14.223 15.265-7.301 19.342-.996 3.272 12.232 0 19.77-3.84 20.908z"
      fill="#FB6666"
      fillOpacity={0.13}
    />
    <path
      d="M116.594 87.384c-3.186-.91-5.594-4.267-6.115-6.116 9.102 1.934 12.373 9.103 11.378 13.654-.711-1.185-2.077-6.628-5.263-7.538z"
      fill="#0D1453"
    />
    <path
      d="M103.509 95.349c0-8.676 6.921-10.762 7.253-14.08-13.938 0-14.507 17.209-10.525 23.609 0 0 3.272-.854 3.272-9.53z"
      fill="#0D1453"
    />
    <path
      d="M73.926 166.034c-3.982-40.619 17.21-51.343 25.6-50.205 10.099 3.129 16.1 7.737 25.886 0 18.062 0 20.337 36.125 21.333 38.401.996 2.275 14.934 10.951 14.934 10.951.935 6.122-.115 8.965-5.12 13.227-8.96-2.9-4.552-1.281-17.938-8.105.024 4.013.159 7.801.159 10.523 0 6.589 2.703 22.329 1.138 26.738-1.564 4.409-22.187 4.693-34.845-1.422-12.658-6.116-20.684 6.783-26.596 1.422-5.143-4.664 1.896-13.789 1.991-19.769.08-5.035 0-6.969 0-6.969-4.421-5.324-5.886-11.052-6.542-14.792z"
      fill="#6764F9"
    />
    <path
      d="M87.153 120.522c-8.401 10.383-13.227 23.325-11.093 52.481-4.125-7.964-5.405-40.249 11.093-52.481z"
      fill="#000"
      fillOpacity={0.1}
    />
    <path
      d="M113.322 137.163c-10.789-1.693-22.282-12.943-22.329-19.343 12.658-7.68 19.2 9.529 34.134-1.848 14.365-.57 18.916 22.328 20.907 33.849-1.09-1.375-.979-3.128-2.418-6.827-1.991-5.12-2.702-15.218-5.689-15.36-2.986-.143-17.351 10.666-24.605 9.529z"
      fill="#fff"
      fillOpacity={0.02}
    />
    <path
      d="M79.757 191.777c.854-3.698.854-10.809.854-10.809 6.684 6.4 10.951 9.529 26.596 14.507-2.987-.664-11.634-.711-19.485-.711-9.813 0-10.382 6.274-10.382 3.84 0-1.991 1.564-3.129 2.417-6.827z"
      fill="#000"
      fillOpacity={0.04}
    />
    <path
      d="M140.203 153.519c-1.565 3.555-1.565 11.709-1.565 16.64l1.565.711s-1.141-7.253.853-15.787c1.995-8.533-3.388-15.787-6.4-18.347 5.575 8.42 6.021 14.697 5.547 16.783z"
      fill="#000"
      fillOpacity={0.09}
    />
    <path
      d="M94.264 168.452c4.611 6.341 25.458 9.245 25.458 9.245s-19.736 3.048-27.591-6.116c-6.827-7.964-.711-23.183-.711-23.183s-1.767 13.714 2.844 20.054z"
      fill="#fff"
      fillOpacity={0.03}
    />
    <path
      d="M156.559 178.266c-1.185-3.177-1.82-10.24 5.12-13.085.664 2.465.569 8.534-5.12 13.085z"
      fill="#000"
      fillOpacity={0.15}
    />
    <path
      d="M104.363 91.224c.995-3.982 5.404-7.254 5.973-9.102 0 0-9.71 4.624-11.236 11.093-1.047 4.444 1.295 7.297.57 11.805-.877 5.446-7.538 11.947-7.538 11.947s5.546-2.134 8.817-.711c.913.397 4.125-1.423 4.125-1.423s.29-1.422.569-2.702c.403-1.848.427-5.262.427-5.262s-1.067-.951-1.707-1.849c-.808-1.134-1.849-3.129-1.849-3.129.853-1.138.853-6.685 1.849-10.667z"
      fill="#9D4AF1"
      fillOpacity={0.12}
    />
    <path
      d="M121.572 197.466c-2.513-3.413-5.746-12.004 1.422-19.058 1.091 5.357 2.333 16.669-1.422 19.058z"
      fill="#030303"
      fillOpacity={0.16}
    />
    <path
      d="M138.496 187.51l-17.636-6.4c-2.134 2.703-3.129 6.685-1.849 10.24l22.756 1.849s2.046.372 2.845 1.138c1.508 1.449 1.168 2.076 2.875 2.502 2.16-.858 2.733-1.88 2.133-3.982 0 0-1.44-2.391-3.16-3.071-1.084-.429-3.84-.569-3.84-.569l-4.124-1.707zM175.617 173.857l-14.791-8.392c-4.776 2.703-5.345 7.823-4.776 10.241l22.838 4.693s2.479-.154 3.414.853c1.543 1.664-2.134 3.084-2.134 5.405 0 .711 3.842-1.318 4.753-3.413.709-1.631.794-3.84.794-3.84l-2.418-3.129-2.56-1.138-5.12-1.28z"
      fill="#EAAFAF"
    />
    <path
      d="M177.324 180.115l-21.192-4.409c-.426-1.423-.284-3.272 0-3.698l21.192 8.107z"
      fill="#F94545"
      fillOpacity={0.14}
    />
    <path
      d="M141.768 193.199s-1.275-.333-1.991-.426c-6.543-.854-10.525-2.134-17.21-2.987-1.895-.242-3.982-2.845-3.982-2.845-.284 1.28-.142 2.845.569 4.409l22.614 1.849z"
      fill="#FE3636"
      fillOpacity={0.08}
    />
    <path
      d="M135.794 188.079c-5.831-2.418-16.64-3.698-16.64-3.698l.995-2.275.854-.996 21.618 8.107s-5.236-.478-6.827-1.138z"
      fill="#fff"
      fillOpacity={0.07}
    />
    <path
      d="M96.397 92.93c1.565-9.528 3.983-9.102 9.245-11.52-6.944 4.125-7.965 7.68-8.391 11.52-.342 3.073-7.364 5.22-6.827 9.245.569 4.267-.095 5.073-1.991 6.116 3.555-2.276-.266-4.878 1.28-8.391 1.519-3.452 5.973-4.527 6.684-6.97z"
      fill="#fff"
      fillOpacity={0.05}
    />
    <path
      d="M122.852 178.266s-21.05-2.987-27.592-9.245c-5.853-5.598-3.84-20.623-3.84-20.623"
      stroke="#000"
      strokeOpacity={0.06}
      strokeWidth={0.669}
    />
    <path
      d="M129.71 73.66v-3.104h2.845v3.103h-2.845z"
      stroke="#fff"
      strokeWidth={1.338}
    />
    <path
      d="M84.198 69.806v-3.103h2.845v3.103h-2.845z"
      stroke="#fff"
      strokeWidth={0.405}
    />
    <path
      d="M119.896 57.885l2.418-4.693 2.702 4.693h-5.12zM73.389 83.628l1.564-5.262 2.987 4.124-4.551 1.138zM142.795 91.166V87.61h3.698v3.556h-3.698zM151.612 77.086v3.555m-1.564-1.706h3.271"
      stroke="#fff"
      strokeWidth={0.669}
    />
    <path
      d="M103.967 55.61v3.555m-1.565-1.706h3.272"
      stroke="#fff"
      strokeWidth={1.338}
    />
  </svg>
);

export default SvgUseCaseConsolidate;
