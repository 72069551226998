import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { components, colors } from 'herald-fe-shared';

import Icon from '~/images/favicon.png';
import '~/layout/global.css';

interface IQuery {
  site: {
    siteMetadata: ISiteMetadata;
  };
}

interface IProps {
  title?: string;
  description?: string;
  image?: string;
  app?: boolean;
}

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        twitterHandle
      }
    }
  }
`;

const LayoutHead: React.FC<IProps> = (props) => {
  const data: IQuery = useStaticQuery(query);
  const { title: appName, twitterHandle } = data.site.siteMetadata;

  const title = props.image
    ? props.title
    : `${appName}${props.title ? ` | ${props.title}` : ''}`;
  const description =
    props.description ||
    'Organize and quantify customer feedback to make better products.';
  const image = props.image;

  const metaValues = [
    {
      property: 'og:type',
      content: 'article',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: twitterHandle,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      name: 'twitter:title',
      content: title,
    },
  ];
  if (description) {
    metaValues.push(
      {
        name: `description`,
        content: description,
      },
      {
        property: `og:description`,
        content: description,
      },
      {
        name: `twitter:description`,
        content: description,
      }
    );
  }
  if (image) {
    metaValues.push(
      {
        property: `og:image`,
        content: image,
      },
      {
        name: 'twitter:image',
        content: image,
      }
    );
  }

  return (
    <ThemeProvider theme={{ color: colors.PURPLE_LIGHT }}>
      <components.GlobalStyle />
      <Helmet
        link={[
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: `${Icon}`,
          },
          {
            rel: 'icon',
            type: 'image/png',
            sizes: '32x32',
            href: `${Icon}`,
          },
          {
            rel: 'shortcut icon',
            type: 'image/png',
            href: `${Icon}`,
          },
        ]}
        title={title}
        meta={metaValues}
        bodyAttributes={{
          class: props.app ? 'app' : '',
        }}
      />

      {props.children}
    </ThemeProvider>
  );
};

export const Container = styled.div`
  margin: auto;
  width: 100%;
  padding: 0 2rem;
  height: 100%;
  max-width: 1280px;
`;

export default LayoutHead;
