import * as React from 'react';

const SvgIllustrationFlag = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 389 315" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.158 310.641a1.664 1.664 0 000 3.329h248.079a1.664 1.664 0 000-3.329H13.158zm281.837.346a1.664 1.664 0 000 3.329h47.954a1.664 1.664 0 000-3.329h-47.954z"
      fill="#3E3E3E"
    />
    <path
      d="M313.846 55.2c-3.316-9.44 15.993-12.53 24.549-7.342 4.51 2.735-7.652 5.482-3.616 8.877 8.158 6.864 40.341-3.461 38.358 7.014-1.004 5.305-11.81 4.057-12.384 9.425-.999 9.341 23.85-3.864 26.083 5.261 1.759 7.184 3.003 7.653-4.055 9.864-18.192 5.698-26.025 1.424-39.892 1.424-21.015 0-28.283.394-48.112 0-6.804-.135-17.966 2.566-19.947-3.945-1.764-5.801 5.646-9.675 11.837-14.028 12.586-8.85 46.944 5.364 36.823-8-3.654-4.827-7.637-2.837-9.644-8.55zM39.28 9.845C35.964.403 55.273-2.687 63.83 2.502c4.51 2.734-7.653 5.482-3.617 8.877 8.158 6.863 40.341-3.461 38.358 7.014-1.004 5.305-11.81 4.056-12.384 9.425-.999 9.341 23.85-3.864 26.083 5.26 1.759 7.184 3.003 7.653-4.055 9.864-18.192 5.7-26.025 1.425-39.892 1.425-21.015 0-28.283.394-48.112 0-6.804-.135-17.966 2.565-19.946-3.945C-1.5 34.62 5.91 30.745 12.1 26.393c12.586-8.85 46.944 5.365 36.824-8-3.655-4.826-7.638-2.837-9.645-8.548z"
      fill="#94CDEC"
      fillOpacity={0.14}
    />
    <path
      d="M299.683 265.099c14.553 8.05-6.063 35.091-18.19 47.605h-22.061c-15.675-7.16-44.587-22.68-34.834-27.48 12.192-5.999-6.773-12.772-12.191-28.06-5.419-15.288 16.062-14.901 31.543-14.901 15.482 0 3.29-20.125 19.545-24.77 16.256-4.644 10.644 13.353 10.644 24.77 0 11.418 7.354 12.773 25.544 22.836z"
      fill="#8280FF"
    />
    <path
      d="M258.408 244.365c4.105 6.842 6.931 44.882 12.608 67.588h-6.387c.968-3.096-18.771-13.159-25.157-17.223-6.386-4.064.774-11.03 3.29-12.192 2.516-1.161-21.093-11.998-17.417-25.931 2.728-10.336 17.003 12.197 20.9 1.935 2.473-6.512 9.26-19.015 12.163-14.177z"
      fill="#fff"
      fillOpacity={0.13}
    />
    <path
      d="M272.564 312.341c-1.19-3.288-2.659-7.766-4.16-12.966m-2.033-69.471c-4.451 6.386-.385 9.649-2.901 21.26-2.828 13.053-.727 26.649 2.901 40.761m0 0c-8.257-11.127-18.771-21.384-35.801-31.834m35.801 31.834a261.47 261.47 0 002.033 7.45m0 0c4.676-6.644 15.926-20.745 23.512-23.996"
      stroke="#FBFBFB"
      strokeWidth={1.395}
      strokeLinecap="round"
    />
    <path
      d="M248.704 255.928c-17.42 9.637 7.258 42.005 21.774 56.985h26.408c18.763-8.571 53.371-27.149 41.696-32.894-14.593-7.181 8.108-15.289 14.594-33.589 6.486-18.3-19.226-17.836-37.758-17.836-18.532 0-3.938-24.091-23.396-29.651-19.459-5.559-12.741 15.984-12.741 29.651s-8.803 15.288-30.577 27.334z"
      fill="#FCAABE"
    />
    <path
      d="M298.11 231.11c-4.914 8.19-8.296 53.725-15.091 80.905h7.644c-1.158-3.706 22.47-15.752 30.114-20.616 7.644-4.865-12.107-5.592-12.107-11.815 0-9.695 33.418-17.141 29.017-33.82-3.265-12.372-20.353 14.6-25.018 2.317-2.96-7.795-11.084-22.762-14.559-16.971z"
      fill="#fff"
      fillOpacity={0.13}
    />
    <path
      d="M281.166 312.479c1.424-3.936 3.182-9.297 4.979-15.52m2.433-83.16c5.328 7.645.462 11.55 3.473 25.449 3.386 15.625.87 31.9-3.473 48.792m0 0c9.884-13.319 22.47-25.596 42.855-38.105m-42.855 38.105c-.8 3.115-1.621 6.105-2.433 8.919m0 0c-5.598-7.954-19.063-24.833-28.144-28.724"
      stroke="#FBFBFB"
      strokeWidth={1.67}
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.722 205.145s-13.503-5.54-15.408-1.212c-1.904 4.328-2.77 41.404-2.77 41.404 1.674 2.366 5.956 1.149 8.31-4.53 1.436-3.463 1.594-4.371 1.742-5.217.155-.888.298-1.708 1.894-5.343.798-1.818 1.805-3.813 2.7-5.583 1.042-2.065 1.93-3.823 2.147-4.631l1.385-14.888zm170.695 93.137s-1.731 14.542.693 15.436c2.423.893 44.318-4.01 37.393-12.984-1.72-.246-3.933-.445-6.327-.66-7.727-.694-17.33-1.556-18.256-4.707l-13.503 2.915z"
      fill="#31323B"
    />
    <path
      d="M23.89 245.308c.289-13.561 1.247-41.064 2.77-42.587"
      stroke="#C4C8CA"
      strokeWidth={1.664}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M160.46 190.443c-9.709 18.868-18.862 35.384-22.831 39.14-7.755 7.34-68.208-3.174-97.465-9.349L41.549 205c11.88 2.823 26.273 1.551 39.991.339 17.265-1.526 33.461-2.957 42.24 3.989 0-23.314 3.047-72.329 15.234-81.885 8.31 4.27 29.292 12.568 46.742 11.599l-.163.346 28.728 80.846 11.772 74.961c-3.116 1.211-10.284 3.393-14.023 3.116l-8.104-29.603c-4.789-6.464-14.057-23.718-12.81-41.03-8.272-5.932-26.14-22.67-30.696-37.235z"
      fill="#304FF3"
    />
    <path
      d="M41.549 205v5.685c38.778-2.742 74.094 8.136 84.481 6.059 10.388-2.078 10.388-50.032 25.968-83.801-5.449-1.944-10.021-3.978-12.984-5.5-12.187 9.556-15.234 58.571-15.234 81.885-15.754-12.465-55.398 2.049-82.231-4.328z"
      fill="#000"
      fillOpacity={0.11}
    />
    <path
      d="M214.321 220.234l-28.737-80.874-25.276 50.578c4.294 14.681 22.481 31.739 30.848 37.74-1.246 17.312 8.022 34.566 12.811 41.029l8.104 29.603c3.739.277 10.906-1.904 14.022-3.116l-11.772-74.96z"
      fill="#000"
      fillOpacity={0.14}
    />
    <path
      d="M212.071 298.31c3.739.277 3.808-.028 6.925-1.24 0 0-6.373-18.996-10.907-31.681-5.499-15.384-12.118-41.375-12.118-41.375s-9.949-11.178-15.754-18.35c-6.791-8.391-15.927-23.718-15.927-23.718l-3.982 7.992c4.294 14.68 22.481 31.738 30.848 37.74-1.246 17.312 8.022 34.566 12.811 41.029l8.104 29.603z"
      fill="#000"
      fillOpacity={0.13}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M203.068 56.29c.462-2.423 1.815-7.824 2.369-8.655 3.149 1.551 8.19 1.678 13.558-4.848h2.597c-.058-3.116.242-10.11 1.904-13.157 3.463-17.831-29.603-26.14-30.295 3.29v6.578l-6.752 9.695-3.116 5.366c.981 3.578 5.609 10.11 16.273 7.617l3.462-5.885zm-65.265 74.961l-23.718-40.51c10.907-2.77 19.57-7.901 28.738-14.247-7.479-7.797-8.887-17.987-8.656-22.108-12.118 5.54-40.163 22.108-43.106 31.854.415 13.988 28.91 45.761 43.106 59.899l3.636 10.533 5.539 2.077 5.886 1.731 6.406-8.656-4.155-7.79-8.829-4.847-4.847-7.936zm97.985-.52c-13.711-7.894-20.948-26.602-22.852-34.97 8.829-2.596 15.927-8.31 17.831-12.464 1.921 2.56 3.513 7.698 5.193 13.12 1.592 5.136 3.263 10.528 5.368 14.233 10.56-3.491 27.41-6.29 35.835-6.06 8.425.231 20.774-3.981 20.774-3.981.494-.471 1.016-.974 1.548-1.487 3.446-3.326 7.309-7.053 6.589-4.774l-.693 4.876c5.886-2.106 16.735-2.597 15.927 0-1.025 9.232-9.329 8.934-15.984 8.695-1.207-.044-2.36-.085-3.405-.067-13.572 7.201-51.589 19.186-66.131 22.879z"
      fill="#FA2D5E"
    />
    <path
      d="M189.565 52.108c-.97-.277-2.193-1.846-3.117-2.943l6.752-9.521 8.137-4.155s-5.288 3.59-7.271 6.405c-2.684 3.81-4.501 10.214-4.501 10.214z"
      fill="#000"
      fillOpacity={0.12}
    />
    <path
      d="M218.822 93.83c-1.246 1.246-3.751 1.385-5.886 2.077 4.847 21.12 19.909 34.624 23.025 34.624-19.39-25.968-15.893-37.948-17.139-36.702z"
      fill="#000"
      fillOpacity={0.1}
    />
    <path
      d="M239.423 111.661c-2.867-3.879-8.194-18.12-10.041-25.448-.105-.416.707-1.154 1.558-2.25 2.078.692 5.367 15.926 10.388 26.659 24.063-7.444 34.104-4.328 47.434-7.444-3.462 2.078-6.925 3.463-14.888 4.848-2.729.474-15.061-1.363-22.679 2.596-3.289 1.27-8.829 5.021-11.772 1.039z"
      fill="#000"
      fillOpacity={0.06}
    />
    <path
      d="M111.142 88.636c6.822-3.6 14.716-6.06 29.431-14.715l2.077 2.424c-7.79 6.578-21.12 12.637-28.391 14.541l21.812 37.567-5.193-6.232c-5.817-5.955-25.968-30.296-19.736-33.585z"
      fill="#000"
      fillOpacity={0.09}
    />
    <path
      d="M201.51 35.517l-8.31 4.127c-3.462-37.74 32.2-27.844 30.469-12.264-2.077 2.25-9.892-4.694-13.676-2.424-2.597 1.559-2.482 5.02-3.116 7.445h-1.732c-2.423-5.02-5.712 0-3.635 3.116z"
      fill="#071750"
    />
    <path
      d="M193.719 27.871c2.424.866 5.852-6.44 9.868-6.578 4.016-.139 11.253-2.944 12.638-5.713M194.932 22.85c2.423.866 5.503-6.405 9.521-6.405 3.116 0 4.848-.346 8.31-2.423M193.373 33.584c2.424.866 6.891-7.651 10.907-7.79 4.016-.138 13.503-5.367 14.888-8.137"
      stroke="#fff"
      strokeOpacity={0.07}
      strokeWidth={1.664}
    />
    <path
      d="M150.786 73.575l-7.79 2.97c-7.202-5.678-9.002-17.6-9.002-22.85 17.035-11.635 41.837-8.08 52.108-4.848 7.202 8.586 14.542 8.425 17.312 7.27 15.512 8.033 24.814 21.698 27.526 27.527-4.432 7.34-13.734 11.368-17.831 12.464L209.3 89.53l-23.544 49.685c-19.666 1.108-39.067-7.387-46.742-11.772l11.772-53.868z"
      fill="#E8F4F4"
    />
    <path
      d="M209.819 88.809l-24.063 49.858c-1.731 1.212-9.512.577-13.85 0 11.773-20.428 22.506-19.389 37.913-49.858z"
      fill="#0A0A0A"
      fillOpacity={0.05}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M149.227 142.649l95.216-109.065 17.138-18.696 5.367 3.981L153.036 147.15s-.753-1.818-1.558-2.77c-.683-.808-2.251-1.731-2.251-1.731zm-26.66 29.776l14.715-17.658c.502 1.607 1.586 1.99 2.747 2.401.84.297 1.72.608 2.447 1.408l-56.437 63.275-3.116-4.069 39.644-45.357z"
      fill="#737373"
    />
    <path
      d="M294.994 57.475c-14.374-11.35-32.373-33.931-32.373-33.931s-11.054 13.002-18.523 21.466c-7.75 8.782-20.255 21.986-20.255 21.986s17.113 18.55 29.776 29.95c13.131 11.822 35.662 29.43 35.662 29.43s15.219-15.203 23.025-25.448c6.986-9.168 13.33-24.237 13.33-24.237s-19.556-10.463-30.642-19.216z"
      fill="#CEE1F7"
    />
    <path
      d="M213.252 312.512a.832.832 0 10.061 1.663l-.061-1.663zm38.36-9.049a.832.832 0 00-1.525-.667l1.525.667zm-38.299 10.712c5.461-.202 13.997-.68 21.637-2.157 3.818-.738 7.452-1.733 10.379-3.089 2.905-1.345 5.252-3.109 6.283-5.466l-1.525-.667c-.787 1.798-2.682 3.338-5.457 4.623-2.754 1.275-6.239 2.239-9.995 2.965-7.508 1.452-15.938 1.926-21.383 2.128l.061 1.663z"
      fill="#E8E8E8"
    />
    <path
      d="M201.856 45.356c-.692 1.386-2.77 5.54-7.963 9.695 4.501 2.424 9.116 1.45 9.175 1.212.173-.692 1.039-5.367 2.424-8.83-1.039.347-3.289-1.21-3.636-2.077z"
      fill="#000"
      fillOpacity={0.12}
    />
    <path
      d="M151.306 72.362l-12.119 55.052 7.964 4.155c-1.731-3.809 1.962-41.318 4.155-59.207z"
      fill="#000"
      fillOpacity={0.06}
    />
    <path
      d="M135.379 63.014c-1.108-2.77-1.27-7.501-1.212-9.521 15.234-10.803 40.163-8.137 52.282-4.501l1.038 1.904-3.462-.52-12.811-.692c-26.037 0-33.873 9.291-35.835 13.33z"
      fill="#000"
      fillOpacity={0.04}
    />
    <path
      d="M264.872 17.657L151.999 145.073l1.211 1.731L266.949 19.216l-2.077-1.559z"
      fill="#000"
      fillOpacity={0.12}
    />
    <path
      d="M84.483 220.206l56.263-62.842 1.731 1.038-56.61 63.535-1.385-1.731z"
      fill="#000"
      fillOpacity={0.16}
    />
    <circle cx={268.507} cy={258.81} r={2.597} fill="#fff" fillOpacity={0.2} />
    <circle cx={279.587} cy={266.428} r={1.558} fill="#fff" fillOpacity={0.2} />
    <circle cx={284.434} cy={248.077} r={1.558} fill="#fff" fillOpacity={0.2} />
    <circle cx={296.899} cy={221.763} r={1.558} fill="#fff" fillOpacity={0.2} />
    <circle cx={316.98} cy={240.113} r={1.558} fill="#fff" fillOpacity={0.2} />
    <circle cx={331.522} cy={236.997} r={1.558} fill="#fff" fillOpacity={0.2} />
    <circle cx={345.372} cy={246.345} r={1.558} fill="#fff" fillOpacity={0.2} />
    <circle cx={261.582} cy={282.008} r={1.558} fill="#fff" fillOpacity={0.2} />
    <circle cx={276.47} cy={293.088} r={1.558} fill="#fff" fillOpacity={0.2} />
    <circle cx={329.791} cy={279.238} r={1.558} fill="#fff" fillOpacity={0.2} />
    <circle cx={266.777} cy={300.013} r={1.558} fill="#fff" fillOpacity={0.2} />
  </svg>
);

export default SvgIllustrationFlag;
